import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {PettyCashForm} from "@/modules/pettyCash/interfaces"
import store from "@/store";
import useTopbarOptions from "@/composables/useTopbarOptions";

const {companyId} = useAuth()
const {topbarMenuName} = useTopbarOptions()
const defaultValues = (): PettyCashForm => {
    return {
        id: uuid(),
        name: "",
        status: "active",
        accountingCenterId: '',
        managerId: '',
        balance: "",
        limited: 0,
        type: topbarMenuName.value,
        companyId: companyId.value
    }
}

const pettyCashForm: Ref<PettyCashForm> = ref(defaultValues())

const usePettyCash = () => {
    const catalogs = {
        status: [
            {id: 'active', title: 'Activo'},
            {id: 'inactive', title: 'Inactivo'},
        ],
        type: [
            {id: 'purchase', title: 'Caja de compras'},
            {id: 'sale', title: 'Caja de ventas'}
        ]
    };
    const clearForm = () => pettyCashForm.value = defaultValues()

    const createPettyCash = async (pettyCashForm: PettyCashForm) => {
        return await store.dispatch('pettyCash/createPettyCash', pettyCashForm)
    }

    const updatePettyCash = async (pettyCashForm: PettyCashForm) => {
        return await store.dispatch('pettyCash/updatePettyCash', pettyCashForm)
    }

    const getPettyCash = async (id: string): Promise<PettyCashForm> => {
        const {ok, data} = await store.dispatch('pettyCash/getPettyCash', id)

        return data;
    }


    return {
        pettyCashForm,
        catalogs,
        clearForm,
        createPettyCash,
        updatePettyCash,
        getPettyCash
    }
}

export default usePettyCash;
