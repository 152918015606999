import { computed, onMounted, Ref, ref } from "vue";
import store from "@/store";

const useTopbarOptions = () => {
    const topBarOptions = ref([
        { name: "inventory", title: "Inventario" },
        { name: "purchase", title: "Compras" },
        { name: "sale", title: "Ventas" },
        { name: "accounting", title: "Contabilidad" },
    ]);

    const topBarOptionSelected: Ref<string | null> = ref(null);

    const getLeftBarOptions = async (name: string) => {
        topBarOptionSelected.value = name;
        localStorage.setItem("topBarOptionSelected", name);

        await store.dispatch("topbarMenuNameChanged", name);
    };

    onMounted(async () => {
        const name = localStorage.getItem("topBarOptionSelected");

        if (name) {
            await getLeftBarOptions(name);
        }
    });

    return {
        topBarOptions,
        topBarOptionSelected,
        getLeftBarOptions,
        topbarMenuName: computed<string>(() => {
            const topBarOptionSelected = localStorage.getItem("topBarOptionSelected") || "";

            return store.state.topbarMenuName !== "" ? store.state.topbarMenuName : topBarOptionSelected;
        }),
    };
};

export default useTopbarOptions;
