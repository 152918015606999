
import {onMounted, ref, watch} from "vue";
import usePettyCash from "@/modules/pettyCash/composables/usePettyCash";
import usePanel from "@/composables/usePanel";
import {baseURL} from "@/api/erpApi";

const {pettyCashForm} = usePettyCash()

export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()

        const name = ref('')
        const accountingCenterId = ref('')
        const managerId = ref('')
        const status = ref('')
        const limited = ref(0)
        const balance = ref('')
        const type = ref('')

        const select2center = {
            ajax: {
                url: () => baseURL + '/accounting_centers/select2'
            },
        }

        const select2user = {
            ajax: {
                url: () => baseURL + '/users/select2'
            },
        }

        onMounted(() => {
            name.value = pettyCashForm.value.name
            accountingCenterId.value = pettyCashForm.value.accountingCenterId
            managerId.value = pettyCashForm.value.managerId
            status.value = pettyCashForm.value.status
            limited.value = pettyCashForm.value.limited
            balance.value = pettyCashForm.value.balance
            type.value = pettyCashForm.value.type
        })

        watch(name, val => pettyCashForm.value.name = val)
        watch(accountingCenterId, val => pettyCashForm.value.accountingCenterId = val)
        watch(managerId, val => pettyCashForm.value.managerId = val)
        watch(status, val => pettyCashForm.value.status = val)
        watch(limited, val => pettyCashForm.value.limited = val)
        watch(balance, val => pettyCashForm.value.balance = val)
        watch(type, val => pettyCashForm.value.type = val)

        return {
            select2center,
            select2user,
            showPanel,
            togglePanel,
            name,
            accountingCenterId,
            managerId,
            status,
            limited,
            balance,
            type,
            pettyCashForm
        }
    }
}
